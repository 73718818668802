@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'JackArmstrong';
  src: url('/fonts/JackArmstrong.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JackArmstrong';
  src: url('/fonts/JackArmstrongBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'JackArmstrongBold';
  src: url('/fonts/JackArmstrongBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansBold';
  src: url('/fonts/OpenSansBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ComicNeueBold';
  src: url('/fonts/ComicNeueBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Anton';
  src: url('/fonts/Anton.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bangers';
  src: url('/fonts/Bangers.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@layer base {
  html,
  body,
  #__next {
    height: 100%;
  }
  body,
  #__next {
    display: flex;
    flex-direction: column;
  }

  main {
    flex: 1 0 auto;
  }

  .site-footer {
    flex-shrink: 0;
  }

  #pera-wallet-connect-modal-wrapper,
  #pera-wallet-redirect-modal-wrapper,
  #pera-wallet-sign-txn-toast-wrapper,
  #defly-wallet-sign-txn-toast-wrapper {
    z-index: 99999;
  }
}

@layer components {
  .calendar-dropdown > div {
    @apply absolute left-0 w-56 mt-2 origin-top-left rounded-md shadow-lg bg-dropdown ring-1 ring-black ring-opacity-5 focus:outline-none;
  }

  table {
    @apply w-full overflow-scroll;
    border-spacing: 0;
    overflow: hidden;
    border-radius: 8px;
  }

  tr th {
    @apply bg-black/50;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr:nth-child(odd) td {
    @apply bg-black/25;
  }

  tr:nth-child(even) td {
    @apply bg-black/50;
  }

  th,
  td {
    @apply m-0 p-3;
    @apply text-center;
  }

  th {
    @apply text-gray-500;
  }

  th:first-child,
  td:first-child {
    @apply text-left;
  }

  th:last-child,
  td:last-child {
    border-right: 0;
  }
}
